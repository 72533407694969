
import { defineComponent } from 'vue'
import { getOrderStatusColor } from '@/utils/orderUtils'

export default defineComponent({
  components: {},
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return { getOrderStatusColor }
  },
})
