import axios from '@/services/axios'
import { reactive } from 'vue'
import { useFetchData } from '@/composables'

export default function useOrderItemRepositories() {
  // const resource = `http://localhost:3002/api/order-items`
  const resource = `/order-items`

  const state = reactive<{
    total: any
    orderitems: any[]
    orderitem: any
    multipleSelection: []
  }>({ total: 0, orderitems: [], orderitem: {}, multipleSelection: [] })

  const updateOrderItemVariantTitle = async (body: any) => {
    return await axios.patch(`${resource}/change-variant-title`, body)
  }

  const exportOrderItems = async (params: any) => {
    try {
      return await axios.get(`${resource}/export-items`, { params })
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const importOrderItemsForUpdateCatalog = async (formData: FormData) => {
    try {
      return await axios.patch(`${resource}/import-catalog-code`, formData)
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const getOrderItems = async (params: any) => {
    try {
      const { documents, fetchData, total } = useFetchData(
        '/order-items',
        params
      )
      await fetchData()
      state.total = total
      state.orderitems = documents.value as any[]
      return state.orderitems
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const patchOrderItem = async (id: any, params: any) => {
    try {
      return await axios.patch(`${resource}/${id}/configured-variant`, params)
    } catch (error) {
      console.log(error)
      return null
    }
  }

  return {
    updateOrderItemVariantTitle,
    getOrderItems,
    exportOrderItems,
    importOrderItemsForUpdateCatalog,
    patchOrderItem,
  }
}
