
import { defineComponent, reactive, ref } from 'vue'
// import { useNotification } from '@/composables'
import userCatalogRepositories from '@/repositories/userCatalogRepositories'
import useCatalogRepositories from '@/repositories/useCatalogRepositories'
import { formatterDollars } from '@/utils/formatter'
import { catalogTypes } from '@/utils/catalog'
import { imageUrl } from '@/utils/image'
import SlotError from '@/components/common/SlotError.vue'
import useBaseCostReponsitories from '@/repositories/useBaseCostReponsitories'
import { UserTypeEnum } from '@/utils/constants'
import { useNotification } from '@/composables'
import ConfiguredSetVariant from './ConfiguredSetVariant.vue'
import CloseButton from './buttons/CloseButton.vue'
import SaveButton from './buttons/SaveButton.vue'

export default defineComponent({
  components: { SlotError, ConfiguredSetVariant, SaveButton, CloseButton },

  setup(props, context) {
    const showModalVisible = ref(false)
    const isLoading = ref(false)
    const itemId = ref('')
    const orderItem = ref()
    const variants = ref([])
    const isShowAllVariant = ref(true)
    const variantTitle = ref('')
    const state = reactive({
      catalogs: ref<any[]>([]),
      selectedCatalog: ref<any>(),
      form: {
        catalog: null,
        type: '',
        partner: null,
      },
      partners: ref<any[]>([]),
    })
    const isApplyForProduct = ref(true)
    const info = JSON.parse(localStorage.getItem('info') || '')

    const isViewImage = ref(false)
    const activeTabName = ref('choose')
    const catalogCodeText = ref()

    const { getCatalogs, getCatalog, getFulfillmentPartners } =
      userCatalogRepositories()
    const { checkAllowCreateCatalog } = useCatalogRepositories()
    const isFetchingCatalog = ref(false)
    const getListProductCatalog = async (searchText?: string) => {
      if (!searchText) return
      isFetchingCatalog.value = true
      const params: any = {
        page: 1,
        limit: 10000,
        basecost: info?.sellerTeam?.basecost?.id,
        type: state.form.type ?? 'pod',
        fulfillmentPartner: state.form.partner,
        search: searchText,
      }
      let res = await getCatalogs(params)
      state.catalogs = res as any[]
      state.catalogs.sort((a, b) => a.name.localeCompare(b.name))
      isFetchingCatalog.value = false
    }

    const allowCreateCatalog = ref(false)
    const checkAllowCreateCatalogs = async () => {
      const res = await checkAllowCreateCatalog()
      allowCreateCatalog.value = res?.allowCreateCatalog
    }

    checkAllowCreateCatalogs()

    const sortVariants = () => {
      variants.value = variants.value?.sort((a: any, b: any) =>
        a.color.name.localeCompare(b.color.name)
      )
    }

    const onSelectCatalog = async (catalogId: any) => {
      isLoading.value = true
      if (!catalogId) {
        state.selectedCatalog = {}
        variants.value = []
        isLoading.value = false
        return
      }
      const { data } = await getCatalog(catalogId)
      state.selectedCatalog = data as any
      console.log('variantTitle.value', variantTitle.value)

      let vTitles = variantTitle.value
        .split('/')
        .map(item => item.trim())
        .filter(Boolean)

      await state.selectedCatalog?.variants?.forEach((element: any) => {
        let sizeName = element.size.name || ''
        if (vTitles.includes(sizeName)) {
          element.suggest = true
        }
      })
      await onClickShowMore()
      isLoading.value = false
    }

    const onClickShowMore = async () => {
      isShowAllVariant.value = !isShowAllVariant.value
      const vTitles = (variantTitle.value ?? '')
        .trim()
        .split('/')
        .map(item => item.trim())
        .filter(Boolean)
      let catalogVariants = await state.selectedCatalog?.variants?.filter(
        (element: any) => {
          const sizeName = (element.size?.name ?? '').trim()
          return isShowAllVariant.value || vTitles.includes(sizeName)
        }
      )

      if (catalogVariants?.length === 0) {
        catalogVariants = state.selectedCatalog?.variants
      }

      // lay gia cua seller theo basecost
      catalogVariants = await getSellerBaseCost(catalogVariants)
      variants.value = catalogVariants
      sortVariants()
    }

    const onChooseVariantCode = (row: any) => {
      const designQty = detectDesignQty(state.selectedCatalog, row)
      const {
        _id: catalogId,
        name: catalogName,
        designs: catalogDesigns,
        productCode,
      } = state.selectedCatalog

      context.emit('callbackChangeVariantCode', {
        variantCode: row.catalogVariantCode,
        designQty: designQty,
        itemId: itemId.value,
        selectedCatalog: {
          catalogId,
          catalogName,
          catalogDesigns,
          productCode,
          supplierCode: row.supplierCode || {},
          catalogVariantId: row._id,
        },
        selectedVariant: row,
        isApplyForProduct: isApplyForProduct.value,
      })
      close()
    }

    const detectDesignQty = (catalog: any, variant: any) => {
      let result = 0
      if (variant.designs?.length > 0) {
        result = variant.designs?.length
      } else if (catalog?.designs?.length > 0) {
        result = catalog?.designs?.length
      }
      return result
    }

    const detectDesignName = (catalog: any, variant: any) => {
      let result: any[] = []
      if (variant.designs?.length > 0) {
        const designTemplates = variant.designs
        designTemplates.forEach((item: any) => result.push(item))
      } else if (catalog?.designs?.length > 0) {
        const designTemplates = catalog.designs
        designTemplates.forEach((item: any) => result.push(item.name))
      }
      return result
    }

    const getListFulfillmentPartners = async () => {
      const res = await getFulfillmentPartners()
      if (res?.status === 200) {
        state.partners = res.data
      }
    }

    const { getBaseCost } = useBaseCostReponsitories()

    const basecost = ref()
    const getSellerBaseCost = async (catalogVariants: any) => {
      if (
        catalogVariants?.length > 0 &&
        info?.userType === UserTypeEnum.Seller &&
        info?.sellerTeam?.basecost?.id &&
        !basecost.value
      ) {
        basecost.value = await getBaseCost(info.sellerTeam.basecost.id)
      }

      if (basecost.value) {
        const variantPriceMapping = basecost.value?.variantPriceMapping || {}
        for (const variant of catalogVariants as any) {
          variant.cost = variantPriceMapping[variant._id]
        }
      }
      return catalogVariants
    }

    const { warning } = useNotification()
    const onSaveCatalogWithText = () => {
      if (!catalogCodeText.value) {
        warning('Please input FFM code!')
        return
      }

      const variantCode: string = catalogCodeText.value
        ?.replace(/\n/g, '')
        .split(',')
        .filter((code: string) => code?.trim() !== '')
        .join(',')

      context.emit('callbackChangeVariantCode', {
        variantCode,
        designQty: 0,
        itemId: itemId.value,
        isApplyForProduct: isApplyForProduct.value,
      })
      close()
    }

    const itemInfo = ref({})
    const toggle = async (item: any) => {
      console.log('🚀 ~ file: ChangeCatalogCode.vue:508 ~ toggle ~ item:', item)
      itemInfo.value = item

      itemId.value = item._id
      orderItem.value = item
      variantTitle.value = item.variantTitle
      catalogCodeText.value = item.catalogCode?.split(',')?.join(',\n')
      if (item?.isDropship === true) {
        state.form.type = 'drop'
      } else {
        state.form.type = 'pod'
      }
      activeTabName.value = 'choose'
      getListFulfillmentPartners()
      showModalVisible.value = true
    }

    const close = () => {
      showModalVisible.value = false
    }

    const onChangeApplyForProduct = () => {
      isApplyForProduct.value = !isApplyForProduct.value
    }

    const saveData = () => {
      context.emit('refresh', {})
      close()
    }

    return {
      onChangeApplyForProduct,
      itemInfo,
      toggle,
      close,
      showModalVisible,
      getListProductCatalog,
      state,
      isLoading,
      info,
      onSelectCatalog,
      getCatalog,
      formatterDollars,
      onChooseVariantCode,
      detectDesignQty,
      detectDesignName,
      itemId,
      catalogTypes,
      orderItem,
      variantTitle,
      variants,
      isShowAllVariant,
      onClickShowMore,
      getListFulfillmentPartners,
      imageUrl,
      isViewImage,
      sortVariants,
      basecost,
      getSellerBaseCost,
      isFetchingCatalog,
      activeTabName,
      catalogCodeText,
      onSaveCatalogWithText,
      isApplyForProduct,
      saveData,
      allowCreateCatalog,
    }
  },
})
