
import { defineComponent } from 'vue'
import { formatterDollars } from '@/utils/formatter'
import OrderStatusTag from '@/components/common/tags/OrderStatusTag.vue'

export default defineComponent({
  components: { OrderStatusTag },
  props: { title: { type: String, default: 'Fees' }, order: { type: Object } },
  setup() {
    return { formatterDollars }
  },
})
