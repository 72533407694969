
import { useFetchData } from '@/composables'
import { defineComponent, reactive } from 'vue'
import { useRoute } from 'vue-router'
import { formatTime } from '@/utils/dateTime'

export default defineComponent({
  components: {},
  setup() {
    const { params } = useRoute()
    const queryStr = reactive({ page: 1, limit: 1000 })
    const resource = `order-history/${params.id}`
    const { documents, fetchData, isLoading, total } = useFetchData(
      resource,
      queryStr
    )
    fetchData()

    const checkHollow = (status: string) => {
      // if(documents) {
      //   const findIndex
      // }
    }

    const allTimeLine = [
      {
        status: 'Unfulfilled',
        hollow: true,
      },
      {
        status: 'InReview',
        hollow: true,
      },
      {
        status: 'Approved',
        hollow: true,
      },
      {
        status: 'InProduction',
        hollow: true,
      },
      {
        status: 'InTransit',
        hollow: true,
      },
      {
        status: 'Fulfilled',
        hollow: true,
      },
    ]

    return {
      documents,
      isLoading,
      total,
      formatTime,
      allTimeLine,
      checkHollow,
    }
  },
})
