import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }
const _hoisted_5 = { key: 4 }
const _hoisted_6 = { key: 5 }
const _hoisted_7 = { key: 6 }
const _hoisted_8 = { key: 7 }
const _hoisted_9 = { key: 8 }
const _hoisted_10 = { key: 9 }
const _hoisted_11 = { key: 10 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_timeline_item = _resolveComponent("el-timeline-item")!
  const _component_el_timeline = _resolveComponent("el-timeline")!

  return (_openBlock(), _createBlock(_component_el_timeline, { class: "mt-3" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.documents, (doc) => {
        return (_openBlock(), _createBlock(_component_el_timeline_item, {
          color: "green",
          key: doc._id,
          size: "large",
          hollow: false
        }, {
          default: _withCtx(() => [
            (doc.status === 'Imported')
              ? (_openBlock(), _createElementBlock("b", _hoisted_1, "Order Imported"))
              : (doc.status === 'Unfulfilled')
                ? (_openBlock(), _createElementBlock("b", _hoisted_2, "Order Unfulfilled"))
                : (doc.status === 'InReview')
                  ? (_openBlock(), _createElementBlock("b", _hoisted_3, "Order In Review"))
                  : (doc.status === 'Approved')
                    ? (_openBlock(), _createElementBlock("b", _hoisted_4, "Order Approved"))
                    : (doc.status === 'Rejected')
                      ? (_openBlock(), _createElementBlock("b", _hoisted_5, "Order Rejected"))
                      : (doc.status === 'Cancel')
                        ? (_openBlock(), _createElementBlock("b", _hoisted_6, "Order Cancel"))
                        : (doc.status === 'InProduction')
                          ? (_openBlock(), _createElementBlock("b", _hoisted_7, "Order In Production"))
                          : (doc.status === 'PartiallyFulfilled')
                            ? (_openBlock(), _createElementBlock("b", _hoisted_8, " Order Partially Fulfilled "))
                            : (doc.status === 'Fulfilled')
                              ? (_openBlock(), _createElementBlock("b", _hoisted_9, "Order Fulfilled"))
                              : (_openBlock(), _createElementBlock("b", _hoisted_10, _toDisplayString(doc.status), 1)),
            _createElementVNode("p", null, _toDisplayString(_ctx.formatTime(doc.createdDate)), 1),
            (doc.user?.email)
              ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(doc.user?.email), 1))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}