
import { defineComponent, ref } from 'vue'
import { imageUrl } from '@/utils/image'
import { copyToClipboardV2 } from '@/utils/string'
import UploadDesign from '@/pages/seller/orders/components/UploadDesign.vue'
import UploadImage from '@/pages/seller/orders/components/UploadImage.vue'
import { DocumentCopy } from '@element-plus/icons'
import { useNotification } from '@/composables'
import SaveButton from './buttons/SaveButton.vue'
import useOrderItemRepositories from '@/repositories/useOrderItemRepositories'

export default defineComponent({
  components: { UploadDesign, UploadImage, DocumentCopy, SaveButton },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const ARTWORK_SPACES = ['artwork1', 'artwork2', 'artwork3', 'artwork4']
    const { patchOrderItem } = useOrderItemRepositories()

    const { success, error } = useNotification()

    const listCatalogs = props.item.configuredVariant?.catalogs
      ? ref(props.item.configuredVariant?.catalogs)
      : ref([
          {
            catalogCode: '',
            quantity: 0,
            artworks: {
              artwork1: '',
              artwork2: '',
              artwork3: '',
              artwork4: '',
            },
          },
        ])

    const onAddItem = () => {
      listCatalogs.value.push({
        catalogCode: '',
        quantity: 0,
        artworks: {
          artwork1: '',
          artwork2: '',
          artwork3: '',
          artwork4: '',
        },
      })
    }

    const onRemoveItem = (index: number) => {
      listCatalogs.value.splice(index, 1)
    }

    const changeArtwork = async (data: any) => {
      loading.value = true
      await (listCatalogs.value[data.index].artworks = {
        ...listCatalogs.value[data.index].artworks,
        [data.artwork]: data.path,
      })
      loading.value = false
      success('Uploaded!')
    }

    const uploaded = async (data: any) => {
      await changeArtwork(data)
    }

    const onSaveCatalogs = async () => {
      let emptyCode = false
      let emptyQuantity = false

      listCatalogs.value.forEach((item: any) => {
        if (item.catalogCode === '') {
          emptyCode = true
        }
        if (item.quantity <= 0) {
          emptyQuantity = true
        }
      })

      if (emptyCode) {
        return error('Please fill in the FFM Code')
      }
      if (emptyQuantity) {
        return error('Quantity must be greater than 0')
      }
      const res = await patchOrderItem(props.item.id, {
        variantTitle: props.item.variantTitle,
        sku: props.item.sku,
        catalogs: listCatalogs.value,
      })

      if (res?.status === 200) {
        emit('close')
        success('Saved!')
      } else {
        error('Error! Please try again.')
      }
    }

    return {
      listCatalogs,
      imageUrl,
      ARTWORK_SPACES,
      onAddItem,
      onRemoveItem,
      uploaded,
      changeArtwork,
      loading,
      copyToClipboardV2,
      onSaveCatalogs,
    }
  },
})
